import { mergeProps } from "solid-js";
import { toaster } from "@kobalte/core";
import { Slice } from "./Slice";


export type ToastOptions = {
  title: string;
  message: string;
  type?: "warning" | "success";
  duration?: number;
  persistent?: boolean;
};

const show = (options: ToastOptions) => {
  return toaster.show((inProps) => {
    const props = mergeProps({ options }, inProps);
    return <Slice {...props} />;
  });
};

const update = (id: number, options: ToastOptions) => {
  return toaster.update(id, (inProps) => {
    const props = mergeProps({ options }, inProps);
    return <Slice {...props} />;
  });
};

const dismiss = (id: number) => toaster.dismiss(id);

export const toasty = {
  show,
  update,
  dismiss,
};
