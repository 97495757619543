import { type Component, Switch, Match, Show, ComponentProps } from "solid-js";
import { Toast } from "@kobalte/core";
import { ToastOptions } from "./toasties";
import { Icon } from "solid-heroicons";
import { xMark, check, exclamationCircle } from "solid-heroicons/outline";
import "./toast.css";

type SliceProps = ComponentProps<typeof Toast.Root> & {
  options: ToastOptions;
};

export const Slice: Component<SliceProps> = (props) => {
  return (
    <Toast.Root
      toastId={props.toastId}
      class="toast flex flex-col items-center justify-between gap-2 border border-gray-300 rounded-[6px] p-3 shadow-xl bg-white"
      duration={props.options.duration}
      persistent={props.options.persistent}
    >
      <div class="flex items-start w-full">
        <div>
          <Toast.Title class="text-base font-bold text-roma-dark-grey flex items-center">
            <Switch>
              <Match when={props.options.type === "warning"}>
                <Icon
                  path={exclamationCircle}
                  stroke-width={2}
                  class="w-5 h-5 text-red-500 mr-1"
                />
              </Match>
              <Match when={props.options.type === "success"}>
                <Icon
                  path={check}
                  stroke-width={2.5}
                  class="w-5 h-5 text-success-green mr-1"
                />
              </Match>
            </Switch>
            {props.options.title}
          </Toast.Title>
          <Toast.Description class="text-sm text-roma-dark-grey">
            {props.options.message}
          </Toast.Description>
        </div>
        <Toast.CloseButton class="shrink-0 w-5 h-5 ml-auto text-roma-dark-grey">
          <Icon path={xMark} stroke-width={2} class="w-5 h-5" />
        </Toast.CloseButton>
      </div>
      <Show
        when={props.options.persistent}
        fallback={
          <Toast.ProgressTrack class="h-2 w-full bg-gray-200 rounded-full">
            <Toast.ProgressFill class="h-full bg-roma-blue rounded-full transition-[width] duration-[250] ease-linear w-[var(--kb-toast-progress-fill-width)]" />
          </Toast.ProgressTrack>
        }
      >
        <div class="w-full h-2 rounded-full bg-roma-blue animate-pulse" />
      </Show>
    </Toast.Root>
  );
};
